{
    angular.module("aerosApp").controller("ReportCtrl", ReportController);

    ReportController.$inject = ["$scope", "aerosApi", "$log", "$timeout", "Notification", "configService",
        "ReportService", "wavelengths", "reportOptions", "$uibModal", "EquipmentAndOperatorModal", "project",
        "TestResultsService"];

    function ReportController ($scope, aerosApi, $log, $timeout, Notification, configService, ReportService,
                               wavelengths, reportOptions, $uibModal, EquipmentAndOperatorModal, project,
                               TestResultsService) {

        angular.extend($scope, {
            getFilterOptions
        });

        $scope.reportOptions = reportOptions;
        $scope.wavelengths = wavelengths;
        $scope.moment = moment;

        $scope.differentMPO = ReportService.isDifferentMPO();
        $scope.filteredMediaType = ReportService.filteredMediaType();
        $scope.filteredResultsType = ReportService.filteredResultsType();

        $scope.showActiveData = false;
        $scope.viewby = 1;
        $scope.totalItems = 0;
        $scope.currentPage = 1;
        $scope.itemsPerPage = $scope.viewby;
        $scope.maxSize = 10; //Number of pager buttons to show

        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };
        
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log("Time Zone Value--",timeZone);
        
        $scope.setItemsPerPage = function (num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1; //reset to first page
        };

        $scope.$watch("projectReady", function (newVal) {
            if (!newVal) return;
            if (!$scope.allow['viewReports']) return $scope.reject($scope.projectId);


                getExistingResults($scope.projectId)
                    .then(results => {
                        $scope.allowedReports = ReportService.filterAllowedReportOptions($scope.reportOptions.outputAllowed,
                            $scope.reportOptions.outputAllowedRequiredToolData, $scope.dataExists);
                            $scope.filteredTestTypeOptions = filterTestTypeOptions($scope.reportOptions
                                .allowedTestTypeOptions, $scope.dataExists);
                    });

            //this creates a row of data that contains all the information a fiber's test results could have
            //it is used to set the header size so that it matches the real table when the header needs to be able to float
            createFakeRow(wavelengths);
            function createFakeRow(wavelengths) {
                $scope.fakeRow = {};
                var measurement = {};
                _.forEach(wavelengths, function (wavelength) {
                    measurement["_" + wavelength.value] = {
                        "wavelength": wavelength,
                        "MainToRemote": {
                            "loss": 4.661,
                            "status": "PassedOrComplete",
                            "failedLimits": [],
                            "timestamp": "2015-12-14T21:50:50.977Z"
                        },
                        "RemoteToMain": {
                            "loss": 4.626,
                            "status": "PassedOrComplete",
                            "failedLimits": [],
                            "timestamp": "2015-12-14T21:50:50.977Z"
                        },
                        "Average": {
                            "loss": 4.643,
                            "status": "PassedOrComplete",
                            "failedLimits": []
                        }
                    };
                });
                var refMeasurement = {};
                _.forEach(wavelengths, function (wavelength) {
                    refMeasurement["_" + wavelength.value] = {
                        "losses": {
                            "wavelength": wavelength,
                            "MainToRemote": {
                                "loss": 4.661,
                                "testMethod": "Two-Cord Reference",
                                "timestamp": "2015-12-14T21:50:50.977Z"
                            },
                            "RemoteToMain": {
                                "loss": 4.626,
                                "testMethod": "Two-Cord Reference",
                                "timestamp": "2015-12-14T21:50:50.977Z"
                            }
                        },
                        "referenceMethod": "test_method"
                    };
                });
                var refOrlMeasurement = {};
                _.forEach(wavelengths, function (wavelength) {
                    refOrlMeasurement["_" + wavelength.value] = {
                        "losses": {
                            "wavelength": wavelength,
                            "MainToRemote": {
                                "max_loss": 4.661,
                                "min_loss": 4.661,
                                "testMethod": "Two-Cord Reference",
                                "timestamp": "2015-12-14T21:50:50.977Z"
                            },
                            "RemoteToMain": {
                                "max_loss": 4.661,
                                "min_loss": 4.661,
                                "testMethod": "Two-Cord Reference",
                                "timestamp": "2015-12-14T21:50:50.977Z"
                            }
                        },
                        "referenceMethod": "test_method"
                    };
                });
                // row.id = "";
                // row.name = "name";
                $scope.fakeRow = [{
                    "id": "aglzfmFmbC1kZXZybQsSB1Byb2plY3QiJDZmNGIxODY1LWFmM2MtNDhhZi05ZTVhLWViN2FiN2FhMDk5YQwLEhJGaWJlckdyb3VwUHJvZ3Jlc3MiJDM2NDI4Yjg3LWE1ZTQtNDdmMy1hZmE2LTE3ZDQ1NzdiM2U1MQw",
                    "name": "OM2",
                    "testResults": [{
                        "fiberId": "1",
                        "sortIndex": 1,
                        "status": "Failed",
                        "lengthMeasurement": {
                            "length": 5352.867,
                            "roundedLength": 5353,
                            "status": "PassedOrComplete",
                            "failedLimits": [],
                            "timestamp": "2015-12-14T21:50:50.976Z"
                        },
                        "inspectionMeasurements": {
                            "Remote": {
                                "mainImageUrl": "http://lh3.googleusercontent.com/JCaAhzJiQNjANG-Qmm53RrPrZdrDDP27jwEMKBhRyv041fH6oGhw80qWIjgd2Gy98_pvdxUfawrrhweo31E",
                                "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/remote.jpeg",
                                "overlayImageUrl": "http://lh3.googleusercontent.com/BlWxebfJSS72giKBSsbl69mYm4oUXlF3ILDoDvDY-SiN1upK20ZUIxTLKFoqF9W3yjdvpR7hd7cnrfBB9ZI",
                                "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/remote.gif",
                                "standard": "IEC_61300_3_35_APC_SM",
                                "status": "Failed",
                                "failedLimits": [
                                    null
                                ],
                                "needsMainImageUrl": false,
                                "needsOverlayImageUrl": false,
                                "timestamp": "2015-12-14T21:50:50.981Z"
                            },
                            "Main": {
                                "mainImageUrl": "http://lh3.googleusercontent.com/nj0GEkIqpAVD_lPq9-eBwARa2-V5Bs_vr2zy0MHRbhlekKuhDfD6xnxmrdp6R7ho87hdkSIM1yB2s35h",
                                "mainGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.jpeg",
                                "overlayImageUrl": "http://lh3.googleusercontent.com/rnGMDnmG-i03R5gkJkDrai782z9XS80H0eVKMKvFmqbpLtWTJiF1Zave1B0T8uMp6J7JGf-axOnBbLYj",
                                "overlayGcsFilename": "/gs/afl-dev.appspot.com/project/6f4b1865-af3c-48af-9e5a-eb7ab7aa099a/results/36428b87-a5e4-47f3-afa6-17d4577b3e51/1/main.gif",
                                "standard": "IEC_61300_3_35_APC_SM",
                                "status": "Failed",
                                "failedLimits": [
                                    null
                                ],
                                "needsMainImageUrl": false,
                                "needsOverlayImageUrl": false,
                                "timestamp": "2015-12-14T21:50:50.981Z"
                            }
                        },
                        "lastUpdated": "Dec 14, 2015 9:50:50 PM",
                        "needsImageUrlGeneration": false
                    }],
                    "referenceTestResult": [{
                        "fiberId": "1",
                        "sortIndex": 1,
                        "lastUpdated": "Dec 14, 2015 9:50:50 PM"
                    }]
                }];

                $scope.fakeRow[0].testResults[0].lossMeasurements = measurement;
                $scope.fakeRow[0].testResults[0].orlLossMeasurements = measurement;
                $scope.fakeRow[0].referenceTestResult[0].referenceLossMeasurements = refMeasurement;
                $scope.fakeRow[0].referenceTestResult[0].referenceOrlLossMeasurements = refOrlMeasurement;
            }

            function packageConfig() {
                var request = angular.copy($scope.selectedReport);
                request.filters = angular.copy($scope.selectedFilters);
                _.each(request.filters, function (arr) {
                    arr = _.pull(arr, "All"); // empty array instead of ["All"] used in UI
                });
                $scope.cols = _.uniq($scope.cols);
                request.hideColumns = $scope.cols;

                request.parameters = $scope.equipmentAndOperatorModel ? $scope.equipmentAndOperatorModel : [];
                request.timeZone = timeZone;
                return request;
            }

            function packageSelectedFilters() {
                var request = {};
                request.filters = angular.copy($scope.selectedFilters);
                _.each(request.filters, function (arr) {
                    arr = _.pull(arr, "All"); // empty array instead of ["All"] used in UI
                });
                return request;
            }

            $scope.waitingOnReport = false;
            $scope.reportUrl = null;
            $scope.isReportGenerationError = false;
            $scope.requestReport = function () {

                $scope.waitingOnReport = true;
                var projectId = $scope.projectId;
                var orgId = $scope.organization.id;
                var request = packageConfig();
                aerosApi.requestNewReport(orgId, projectId, request, $scope.organization.id)
                    .then(function (resp) {
                        if (resp.status === 200) {
                            var jobId = resp.data.jobId;
                            $scope.waitingOnReport = true;
                            $scope.reportUrl = undefined;
                            pollForReportCompletion(jobId);
                        } else {
                            Notification.error("Report request failed");
                            $scope.waitingOnReport = false;
                            $scope.reportUrl = undefined;
                            $scope.isReportGenerationError = true;
                        }
                    }, function (err) {
                        Notification.error("Report request error");
                        $scope.waitingOnReport = false;
                        $scope.reportUrl = undefined;
                        $scope.isReportGenerationError = true;
                    });

            };

            function pollForReportCompletion(jobId) {
                return $timeout(function () {
                    var projectId = $scope.projectId;

                    if (!$scope.recentReports || !$scope.recentReports.projectReportInProgress
                        || $scope.recentReports.projectReportInProgress.length === 0) {
                        getRunningNLatestReports($scope.organization.id, projectId);
                    }

                    aerosApi.isReportReady(projectId, jobId, $scope.organization.id)
                        .then(function (resp) {
                            $log.info("Resp: " + resp.status);
                            if (resp.status === 200) {
                                //ready
                                $scope.waitingOnReport = false;
                                $scope.reportUrl = resp.data.url;
                                $scope.reportCompletionStatus = resp.data;
                                $scope.pollForReportCompletionIteration = 0;
                                getRunningNLatestReports($scope.organization.id, projectId);
                            } else if (resp.status === 202) { //waiting
                                // poll again
                                $scope.reportCompletionStatus = resp.data;

                                // random progress data for testing purpose
                                /*$scope.reportCompletionStatus = {
                                 page: parseInt(Math.random() * 300),
                                 totalPages: 300
                                 };*/

                                pollForReportCompletion(jobId);
                            } else {
                                Notification.error("Failed to generate report");
                                $scope.waitingOnReport = false;
                                $scope.reportUrl = undefined;
                                $scope.pollForReportCompletionIteration = 0;
                            }

                            $scope.pollForReportCompletionIteration = $scope.pollForReportCompletionIteration
                                ? $scope.pollForReportCompletionIteration + 1 : 1;

                        }).catch(function (resp) {
                        // failed
                        Notification.error("Error generating report");
                        $scope.waitingOnReport = false;
                        $scope.reportUrl = undefined;
                        $scope.isReportGenerationError = true;
                    });
                }, 2000);
            }

            $scope.cols = [];
            $scope.addToCols = function (colName) {
                $scope.cols.push(colName);
            };
            $scope.removeFromCols = function (colName, i) {
                $scope.colBools[i] = true;
                _.pull($scope.cols, colName);
            };
            $scope.anyColSelected = function () {
                return $scope.cols.length;

            };
            $scope.waitingOnPreview = false;
            $scope.previewJson = null;
            $scope.requestPreview = function () {
                $scope.waitingOnPreview = true;
                var projectId = $scope.projectId;
                var orgId = $scope.organization.id;
                var request = packageConfig();
                $scope.previewJson = undefined;

                aerosApi.requestPreview(orgId, projectId, request, $scope.organization.id)
                    .then(function (rsp) {
                        var jobId = rsp.data;
                        $scope.waitingOnPreview = true;
                        $scope.previewJson = undefined;
                        pollForPreviewCompletion(jobId);
                    }, function (err) {
                        Notification.error("Unable to generate a preview.");
                        $scope.waitingOnPreview = false;
                        $scope.previewJson = undefined;
                    });
            };

            $scope.previewResults = function () {
                loadResults($scope.projectId);
                $scope.showActiveData = true;
            };

            getRunningNLatestReports($scope.organization.id, $scope.projectId);

            //Note: There is currently a copy of this and addUntestedFiberResults in the ResultsController.
            //These should be combined at some point. However, until then make changes there too,
            function loadResults(projectId) {
                if (!$scope.allow['viewReports']) return $scope.reject($scope.projectId);
                configService.getPromise().then(function (config) {
                    var request = packageSelectedFilters();
                    aerosApi.loadProjectResultsReport(projectId, $scope.organization.id, request).success(function (data) {
                        $scope.results = data;
                        $scope.totalItems = $scope.results.length;
                    });

                    getRunningNLatestReports($scope.organization.id, projectId);
                });
            }

            //--------------------------------------------------------------------------------

            function pollForPreviewCompletion(jobId) {
                return $timeout(function () {
                    var projectId = $scope.projectId;
                    aerosApi.pollPreview(jobId)
                        .then(function (resp) {
                            $log.info("Resp: " + resp.status);
                            if (resp.status == 200) {
                                //ready
                                $scope.waitingOnPreview = false;
                                getPreview(jobId);
                            } else if (resp.status == 202) { //waiting
                                // poll again
                                pollForPreviewCompletion(jobId);
                            } else {
                                Notification.error("Failed to generate preview");
                                $scope.waitingOnPreview = false;
                                $scope.previewJson = undefined;
                            }
                        }).catch(function (resp) {
                        // failed
                        Notification.error("Error generating preview");
                        $scope.waitingOnPreview = false;
                        $scope.previewJson = undefined;
                    });
                }, 2000);
            }

            function getPreview(jobId) {
                aerosApi.getPreview(jobId)
                    .then(function (rtn) {
                        $scope.colBools = _.map(rtn.data.fieldNames, function (name, i) {
                            return $scope.cols.indexOf(name) === -1; // "shown" is false if name not in list of hidden columns
                        });
                        $scope.previewJson = rtn.data;
                    }, function (err) {
                        Notification.error("Unable to get Preview");
                        $scope.waitingOnPreview = false;
                        $scope.previewJson = undefined;
                    });
            }

            $scope.master = $scope.project;

            //loadResults($scope.projectId);

            var toggleWatcher = $scope.$watch('project', function (newVal, oldVal) {
                if (!newVal || _.isEmpty(newVal)) return;
                // notify of changes without live update
                if ($scope.cachedProject) {
                    if (_.isEqual(newVal, $scope.cachedProject)) {
                        return;
                    } else {
                        if( $scope.notificationSent == undefined || $scope.notificationSent === false ) {
                            $scope.notificationSent = true;
                            setTimeout(function(){$scope.notificationSent = false;},600000);
                            return Notification.success("Project updates exist. Refresh the page to get changes")
                        } else {
                            return;
                        }
                    }
                }
                $scope.cachedProject = angular.copy(newVal);
                // get project once, sweeten data for incomplete results
                $scope.master = angular.copy(newVal);

                $scope.master.fiberGroups.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                });

                $scope.filterOptions.fiberGroup = _.union(["All"], _.map($scope.master.fiberGroups, "id"));
                $scope.reportOptions.fiberGroup = _.union($scope.reportOptions.fiberGroup, _.map($scope.master.fiberGroups, function (fibergroup) {
                    return {value: fibergroup.id, label: fibergroup.name};
                }));
                _.forEach($scope.master.fiberGroups, function (fibergroup) {
                    $scope.reportOptions.fiberGroupFilterItemType[fibergroup.id] = fibergroup;
                    $scope.reportOptions.labelMap[fibergroup.id] = fibergroup.name;
                });

                _.forEach($scope.results, function (fiberGroup) {
                    for (var i = fiberGroup.startFiber; i <= fiberGroup.fiberCount; i++) {
                        if (_.find(fiberGroup.testResults, {'fiberId': i.toString()}) == null) {
                            fiberGroup.testResults.push({fiberId: i, failedTests: [], status: 'Incomplete'});
                        }
                    }
                });
                // toggleWatcher(); // unbinds the watch
            });
            // markup is bound to filter options
            $scope.filterOptions = {
                fiberType: ["All", ...$scope.filteredMediaType],
                mpoType: ["All",
                    "MPO",
                    "No MPO"
                ],
                fiberGroup: ["All" // populated in project $watch above
                ],
                testType: ["All",
                    "loss",
                    "length",
                    "inspection"
                ],
                resultType: ["All",
                    "PassedOrComplete",
                    "Failed",
                    "Incomplete"
                ]
            };
            $scope.filterOrder = [
                "fiberType",
                "mpoType",
                "fiberGroup",
                "resultType",
                "testType"
            ]
                .filter(item => item !== "mpoType" || $scope.differentMPO)
                .filter(item => item !== "fiberType" || $scope.filteredMediaType.length);

            $scope.selectedFilters = {
                fiberType: ["All"],
                fiberGroup: ["All"],
                mpoType: ["All"],
                testType: ["All"],
                resultType: ["All"]
            };
            $scope.noAll = function (str) {
                return str !== "All";
            };
            $scope.checkboxes = {};
            $scope.setCheckbox = function (filterType, item, val) {
                if (typeof $scope.checkboxes[filterType] === "undefined") {
                    $scope.checkboxes[filterType] = {};
                }
                $scope.checkboxes[filterType][item] = val;
            };
            $scope.toggleSelectedFilter = function ({filterType, item, isChecked}) {
                if (isChecked) {
                    if (item === "All") {
                        $scope.selectedFilters[filterType] = ["All"];
                    } else {
                        $scope.selectedFilters[filterType].push(item);
                        $scope.removeSelectedFilter(filterType, "All");
                        if ($scope.selectedFilters[filterType].length === $scope.reportOptions.filters[filterType].length - 1) {
                            $scope.selectedFilters[filterType] = ["All"];
                            $scope.setCheckbox(filterType, item, false);
                        }
                    }
                } else {
                    if (item === "All") {
                        $scope.setCheckbox(filterType, item, true);
                    } else {
                        $scope.removeSelectedFilter(filterType, item);
                    }
                }
            };

            $scope.filterOptionVisible = function({filterType, item}) {

                function fiberGroupFilter() {
                    let availableFiberType = true;

                    if ($scope.selectedFilters['fiberType']) {
                        if ($scope.selectedFilters['fiberType'].indexOf("All") < 0) {
                            const {fiberType} = $scope.reportOptions.fiberGroupFilterItemType[item];

                            availableFiberType = $scope.selectedFilters['fiberType'].indexOf(fiberType) >= 0;
                        }
                    }

                    let availableMPO = true;

                    if($scope.selectedFilters['mpoType']) {
                        if ($scope.selectedFilters['mpoType'].indexOf("All") < 0) {
                            const {mpoPolarity} = $scope.reportOptions.fiberGroupFilterItemType[item];
                            const mpo = mpoPolarity !== "None" ? "MPO": "No MPO";

                            availableMPO = $scope.selectedFilters['mpoType'].indexOf(mpo) >= 0;
                        }
                    }

                    if (!availableFiberType || !availableMPO) {
                        if ($scope.checkboxes[filterType]
                            && $scope.checkboxes[filterType][item]) {
                            delete $scope.checkboxes[filterType][item];
                            $scope.removeSelectedFilter(filterType, item);
                        }
                    }

                    return availableFiberType && availableMPO;
                }

                function fiberTypeFilter() {

                    if($scope.selectedReport.report.reportName === "Default"){
                        return;
                    }

                    let allowedToolsReport = $scope.reportOptions
                        .outputAllowedRequiredToolData[$scope.selectedReport.report.reportName];

                    let allowedToolsMedia = ReportService.getToolPerMediaType(project.fiberGroups, item);

                    const allowedTools = ReportService.isMediaTypeAllowedBySelectedReport({
                        allowedToolsToReport: allowedToolsReport,
                        allowedToolsForMediaType: allowedToolsMedia
                    });

                    return allowedTools;
                }

                function resultTypeFilter() {

                    if($scope.selectedReport.report.reportName === "Default"){
                        return;
                    }

                    return ReportService.isResultTypeAllowed({
                        fgs: ReportService.filterBySelectedFiberGroups({
                            fiberGroups: ReportService.filterByMediaTypes({
                                fiberGroups: project.fiberGroups,
                                mediaTypes: $scope.selectedFilters['fiberType']
                            }),
                            selectedFGNames: $scope.selectedFilters['fiberGroup']
                        }),
                        resultType: item
                    })
                }

                if(filterType === "fiberGroup" && item !== "All") {
                    return fiberGroupFilter();
                }

                if (filterType === "fiberType" && item !== "All") {
                    return fiberTypeFilter();
                }

                if (filterType === "resultType" && item !== "All") {
                    return resultTypeFilter();
                }

                return true;
            };

            $scope.removeSelectedFilter = function (filterType, item) {
                _.remove($scope.selectedFilters[filterType], function (selection) {
                    return selection === item;
                });
                if ($scope.selectedFilters[filterType].length === 0) {
                    $scope.selectedFilters[filterType] = ["All"];
                }
            };

            var datafilter = {
                fiberGroup: {
                    fiberType: function (fiberGroup) {
                        if ($scope.selectedFilters.fiberType[0] === "All") {
                            return true;
                        }
                        return ($scope.selectedFilters.fiberType.indexOf(fiberGroup.fiberType) !== -1);
                    },
                    fiberGroup: function (fiberGroup) {
                        if ($scope.selectedFilters.fiberGroup[0] === "All") return true;
                        return ($scope.selectedFilters.fiberGroup.indexOf(fiberGroup.id) !== -1);
                    },
                    mpoType: function (fiberGroup) {
                        var setting = $scope.selectedFilters.mpoType[0];
                        if (setting === "All") return true
                        var noMpo = (fiberGroup.mpoPolarity === "None");
                        var setNoMpo = (setting === "No MPO");
                        return (noMpo === setNoMpo);
                    }
                },
                result: {
                    resultType: function (result) {
                        if ($scope.selectedFilters.resultType[0] === "All") return true
                        return ($scope.selectedFilters.resultType.indexOf(result.status) !== -1);
                    }
                },
                test: {
                    testType: function (type) {
                        if ($scope.selectedFilters.testType[0] === "All") return true;
                        return ($scope.selectedFilters.testType.indexOf(type) !== -1);
                    }
                }
            };
            $scope.filterData = function (type, result) {
                var show = true;
                _.each(datafilter[type], function (cb) {
                    if (!show) return;
                    show = cb(result);
                });
                return show;
            };
            $scope.filterResults = function (result) {
                return $scope.filterData("result", result);
            };
            $scope.filterFiberGroups = function (fiberGroup) {
                return $scope.filterData("fiberGroup", fiberGroup);
            };
            $scope.showTestType = function (type) {
                return datafilter.test.testType(type.toLowerCase());
            };
            $scope.showActiveDataSection = function () {
                return $scope.showActiveData;
            };

            $scope.templateOptions = {

                sections: [		// contact sections added by check below
                    "showTeamMembers"
                ],
                reportName: getReportNames(),
                radioSelection: '["Default","pdf"]',
                hideColumns: []
            };

            function getReportNames (){
                var toolTypes = $scope.features;
                console.log("toolTypes:"+toolTypes);
                if(toolTypes.indexOf("otdr")>0){
                    return [
                        "Otdr_Report",
                        "ProjectFiberReportSummary",
                        "ProjectFiberReport",
                        "ExportReport",
                        "CustomLabeledReport",
                        "InspectionReport",
                        "InspectionReportCompact"
                    ];
                }else{
                    return  [
                        "ProjectFiberReportSummary",
                        "ProjectFiberReport",
                        "ExportReport",
                        "CustomLabeledReport",
                        "InspectionReport",
                        "InspectionReportCompact"
                    ];
                }
            };
            $scope.outputOptions = {
                Otdr_Report: ["pdf"],
                ProjectFiberReportSummary: ["pdf"],
                ProjectFiberReport: ["pdf"],
                ExportReport: ["xlsx", "xls", "csv"],
                CustomLabeledReport: ["sor"],
                InspectionReport: ["pdf"],
                InspectionReportCompact: ["pdf"]
            };
            $scope.selectedReport = {
                filters: {}, // set from filter selection above at generate time
                report: {
                    reportName: "Default",
                    output: "pdf",
                    showCustomerContact: false,
                    showConsultantContact: false,
                    showInstallerContact: false,
                    showTeamMembers: false
                },
                hideColumns: []
            };
            $scope.setReportOutput = function (reportName, reportOutput) {
                $scope.selectedReport.report.reportName = reportName;
                $scope.selectedReport.report.output = reportOutput;
            };
            $scope.anySectionSelected = function () {
                // returns true if the value true exists in report section (i.e. "show . . ." keys)
                return !_.difference([true], _.values($scope.selectedReport.report)).length;
            };

            (function checkContacts() {
                aerosApi.getAllProjectContacts($scope.organization.id, $scope.projectId)
                    .then(function (response) {
                        var activeProjectContacts = _.filter(response.data.contacts, function (projContact) {
                            return projContact.contact.status === "Active";
                        });
                        var projectContactsByType = _.groupBy(activeProjectContacts, "contactType");
                        var setTypes = _.keys(projectContactsByType);

                        function showContactSections() {
                            _.each(setTypes, function (type) {
                                $scope.templateOptions.sections.push("show" + type + "Contact");
                            });
                        }

                        // note hard coded check for number of contact types before checking for defaults
                        if (setTypes.length < 3) {
                            aerosApi.getAllContacts($scope.organization.id)
                                .then(function (rsp) {
                                    var defaultContacts = _.filter(rsp.data.contacts, function (orgContact) {
                                        return orgContact.defaultContact;
                                    });
                                    var defaultContactsByType = _.groupBy(defaultContacts, "contactType");
                                    var setDefaults = _.keys(defaultContactsByType);
                                    setTypes = _.union(setTypes, setDefaults);
                                    showContactSections();
                                });
                        } else {
                            showContactSections();
                        }
                    });
            }());

            $scope.reportConfigs = [];
            var checkPersistedConfig = true;
            var defaultConfigSelection = {name: "Select Report Configuration"};
            $scope.initConfigs = function (preselect) {
                // get existing named report configs for this project
                aerosApi.getAllReportConfigs($scope.projectId, $scope.organization.id).then(function (rsp) {
                    if (!rsp.data.reportRequests.length) return;
                    $scope.reportConfigs = _.union([defaultConfigSelection], rsp.data.reportRequests);
                    var index = 0;
                    // get whether user has persisted a selection
                    if (checkPersistedConfig) {
                        checkPersistedConfig = false;
                        $scope.getRememberedConfig().then(function (name) {
                            if (name) {
                                index = _.findIndex($scope.reportConfigs, {name: name});
                                $scope.selectedConfig = $scope.reportConfigs[index];
                                $scope.populateUi($scope.selectedConfig);
                            } else {
                                index = _.findIndex($scope.reportConfigs, defaultConfigSelection);
                                $scope.selectedConfig = $scope.reportConfigs[0];
                                $scope.populateUi($scope.selectedConfig);
                            }
                        });
                    } else if (preselect) {
                        // select passed in config
                        index = _.findIndex($scope.reportConfigs, {name: preselect.name});
                        $scope.selectedConfig = $scope.reportConfigs[index];
                        $scope.populateUi($scope.selectedConfig);
                    } else {
                        // select default
                        index = _.findIndex($scope.reportConfigs, defaultConfigSelection);
                        $scope.selectedConfig = $scope.reportConfigs[index];
                        $scope.populateUi($scope.selectedConfig);
                    }

                });
            };
            $scope.resetFilters = function () {
                $scope.populateUi(defaultConfigSelection);
            };
            $scope.initConfigs();
            $scope.populateUi = function (config) {
                var updateRemembered = false;
                if ($scope.remember && $scope.selectedConfig.name !== config.name) {
                    updateRemembered = true;
                }
                $scope.selectedConfig = config;
                // re-populates default on re-select default - TODO: what name?
                if (_.isEqual(config, defaultConfigSelection)) {
                    config = {
                        report: {
                            reportName: "ProjectFiberReport",
                            output: "pdf",
                            showCustomerContact: false,
                            showConsultantContact: false,
                            showInstallerContact: false,
                            showTeamMembers: false
                        },
                        filters: [],
                        hideColumns: []
                    };
                }
                // set selected filters (incl. "All" option in UI)
                _.each($scope.selectedFilters, function (arr, filterName) {
                    if (config.filters[filterName]) {
                        $scope.selectedFilters[filterName] = config.filters[filterName];
                    } else {
                        $scope.selectedFilters[filterName] = ["All"];
                    }
                });
                // set output type (from reportName + output)
                $scope.templateOptions.radioSelection = JSON.stringify([config.report.reportName, config.report.output])
                // set sections
                $scope.selectedReport.report = config.report;
                // set hiddenColumns
                $scope.cols = _.uniq(config.hideColumns) || [];
                if ($scope.previewJson) {
                    $scope.colBools = _.map($scope.previewJson.fieldNames, function (name, i) {
                        return $scope.cols.indexOf(name) === -1; // "shown" is false if name not in list of hidden columns
                    });
                }
                if (updateRemembered) {
                    $scope.toggleRememberedConfig(true);
                }
            };
            $scope.saveConfig = function () {
                var projectId = $scope.projectId;
                var request = packageConfig();
                var prefill;
                var configsList = $scope.reportConfigs;
                if ($scope.selectedConfig && !_.isEqual($scope.selectedConfig, defaultConfigSelection)) {
                    prefill = $scope.selectedConfig.name;
                }
                $uibModal.open({
                    size: "md",
                    templateUrl: 'saveConfigModal.html',
                    resolve: {
                        config: function () {
                            return request;
                        },
                        prefill: function () {
                            return prefill;
                        },
                        configsList: function () {
                            return configsList;
                        }
                    },
                    controller: ["$scope", "$uibModalInstance", "config", "prefill", "configsList",
                        function ($scope, $uibModalInstance, config, prefill, configsList) {
                            $scope.config = config;
                            $scope.config.name = prefill;
                            $scope.save = function () {
                                // check in-memory list of existing named configs
                                if (_.find(configsList, {name: $scope.config.name})) {
                                    if (confirm("Are you sure you want to overwrite " + $scope.config.name + "?")) {
                                        $uibModalInstance.close({type: "update", config: $scope.config});
                                    }
                                } else {
                                    $uibModalInstance.close({type: "create", config: $scope.config});
                                }
                            }
                        }]
                }).result.then(function (data) {
                    request = data.config;
                    if (data.type === "update") {
                        aerosApi.updateReportConfig(projectId, request.name, request, $scope.organization.id).then(function (rtn) {
                            Notification.success("Updated report configuration " + request.name);
                            if ($scope.remember && $scope.selectedConfig.name !== request.name) {
                                var index = _.findIndex($scope.reportConfigs, {name: request.name});
                                $scope.selectedConfig = $scope.reportConfigs[index];
                                $scope.toggleRememberedConfig(true);
                                checkPersistedConfig = true;
                            }
                            $scope.initConfigs(request);
                        });
                    } else if (data.type === "create") {
                        aerosApi.createReportConfig(projectId, request, $scope.organization.id).then(function (rtn) {
                            Notification.success("Created report configuration " + request.name);
                            if ($scope.remember) {
                                $scope.reportConfigs.push(request);
                                var index = _.findIndex($scope.reportConfigs, {name: request.name});
                                $scope.selectedConfig = $scope.reportConfigs[index];
                                $scope.toggleRememberedConfig(true);
                                checkPersistedConfig = true;
                            }
                            $scope.initConfigs(request);
                        });
                    }
                });
            };

            $scope.getRememberedConfig = function () {
                return aerosApi.getRememberReportConfig($scope.projectId, $scope.organization.id).then(function (rtn) {
                    var data = rtn.data;
                    if (data.rememberedReport && data.rememberedReport.remember) {
                        $scope.remember = true;
                        return data.rememberedReport.name;
                    } else {
                        $scope.remember = false;
                        return false;
                    }
                });
            };
            $scope.toggleRememberedConfig = function (skipToggle) {
                if (!skipToggle) {
                    $scope.remember = !$scope.remember;
                }
                var request = {
                    requestName: $scope.selectedConfig.name,
                    remember: $scope.remember
                };
                aerosApi.saveRememberReportConfig($scope.projectId, request, $scope.organization.id).then(function (rtn) {
                    var data = rtn.data;
                    if (data.message !== "OK") {
                        Notification.error("Error persisting selection");
                        if (!skipToggle) {
                            $scope.remember = !$scope.remember;
                        }
                    }
                });
            };

            function setEquipmentAndOperatorModel() {
                $scope.equipmentAndOperatorFields = [{
                    name: "mainEquipmentSerialNumber",
                    caption: "Main Equipment serial number"
                }, {
                    name: "mainSWVersion",
                    caption: "Main SW Version"
                }, {
                    name: "mainModelNumber",
                    caption: "Main Model Number"
                }, {
                    name: "mainOperator",
                    caption: "Main Operator"
                }, {
                    name: "mainCalibrationDate",
                    caption: "Main Calibration Date",
                    type: 'date'
                }, {
                    name: "remoteEquipmentSerialNumber",
                    caption: "Remote Equipment serial number"
                }, {
                    name: "remoteSWVersion",
                    caption: "Remote SW Version"
                }, {
                    name: "remoteModelNumber",
                    caption: "Remote Model Number"
                }, {
                    name: "remoteOperator",
                    caption: "Remote Operator"
                }, {
                    name: "remoteCalibrationDate",
                    caption: "Remote Calibration Date",
                    type: 'date'
                }];
            }

            $scope.showSetEquipmentAndOperatorModal = function showSetEquipmentAndOperatorModal() {
                aerosApi.getProjectEquipment($scope.organization.id, $scope.projectId).success(function (data) {
                    console.log(data);
                    var saveAsDefault = false;
                    var isNew = true;
                    if(data){
                        $scope.equipmentAndOperatorModel = data;
                        saveAsDefault = true;
                        isNew = false;
                    }
                    EquipmentAndOperatorModal.open($scope.equipmentAndOperatorFields,
                        $scope.equipmentAndOperatorModel, saveAsDefault)
                        .then(function (results) {
                            console.log(results);
                            $scope.equipmentAndOperatorModel = results.equipmentAndOperatorModel;
                            if(!results.saveAsDefault && !isNew){
                                aerosApi.deleteProjectEquipment($scope.organization.id, $scope.projectId, data.id);
                            } else if(results.saveAsDefault && isNew){
                                aerosApi.createProjectEquipment($scope.organization.id, $scope.projectId, $scope.equipmentAndOperatorModel);
                            } else if(results.saveAsDefault && !isNew){
                                aerosApi.updateProjectEquipment($scope.organization.id, $scope.projectId, data.id, $scope.equipmentAndOperatorModel);
                            }
                        });
                });
            };

            (function init() {
                setEquipmentAndOperatorModel();
            })();

            $scope.deselectProjects();
            // trigger resize event to align headers via directive
        });

        function getRunningNLatestReports(orgId, projectId) {
            return aerosApi.reportAPI.getRunningNLatestReports(orgId, projectId, 5)
                .then(function(results) {
                    $scope.recentReports = results.data;
                });
        }

        function getExistingResults(projectId) {
            return TestResultsService.getExistingSummaryResults(projectId, $scope.organization.id)
                .then(dataExists => {
                    return $scope.dataExists = dataExists;
                });
        }

        function filterTestTypeOptions(allowedTestTypeOptions, dataExists) {
            return allowedTestTypeOptions[buildKey(dataExists)];

            function buildKey(dataExists) {
                let key = "";

                if (dataExists['OTDR']) {
                    key += "OTDR";
                }
                if (dataExists["OLTS"] || dataExists["Certification"]) {
                    key += "Loss";
                }
                if (dataExists["Inspection"]) {
                    key += "Inspection"
                }

                return key;
            }
        }

        function getFilterOptions(filterType) {
            if (filterType === "testType") {
                return $scope.filteredTestTypeOptions
                    ? ["All", ...$scope.filteredTestTypeOptions]
                    : ["All"];
            }

            if (filterType === "resultType") {
                return $scope.filteredResultsType
                    ? ["All", ...$scope.filteredResultsType]
                    : ["All"];
            }

            return $scope.filterOptions[filterType];
        }
    }
}


